/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {  useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton, Tooltip, Card, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from '../../../redux/store';
import { getBreakdowns } from '../../../redux/slices/breakdown';
import { getBrands } from '../../../redux/slices/brand';
import axios from '../../../utils/axios';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

export default function BreakDown() {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [editShow,setEditShow]=useState(false)
  const [ID,setID]=useState('')
  const handleShow = () => setShow(true);
  const [tableData, setTableData] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 150,
      },
      {
        accessorKey: 'breakdown_name',
        header: 'Break Down Name',
        size: 200,
      },
      {
        accessorKey: 'brand_id',
        header: 'Brand Name',
        size: 200,
      },
    ],
    []
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { breakdown:{breakdowns},brand:{brands} } = useSelector((state) => state);
console.log(brands)
  const breakdownSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    brand: Yup.string().required('brand is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      brand: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(breakdownSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    handleSubmit,
    setValue,
    formState: { isSubmitting,errors },
  } = methods;

console.log(errors)
  useEffect(() => {
    dispatch(getBreakdowns());
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (breakdowns?.length) {
      setTableData(breakdowns);
    }
  }, [breakdowns]);

  async function handleDelete(rowdata) {
    try {
      await axios.get(`admin/breakdown/delete/${rowdata}`).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar(response?.data?.message);
          dispatch(getBreakdowns());
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  const OnSubmit = async (data) => {
    try {
        const breakdown = new FormData();
        breakdown.append('breakdown_name', data?.name);
        breakdown.append('brand_id', data?.brand);
        breakdown.append('admin_id', 1);
        await axios
          .post('admin/breakdown/store', breakdown)
  
          .then((response) => {
            if (response?.data?.status === true) {
              enqueueSnackbar(response?.data?.message);
              reset();
              handleClose();
              dispatch(getBreakdowns());
            }
          });
      } catch (error) {
        enqueueSnackbar(error?.message, {
          variant: 'error',
        });
        reset();
        console.error(error);
      }
  };

  const OnUpdate = async (data) => {
    try {
      const breakdown=new FormData();
      breakdown.append('breakdown_name',data?.name)
      breakdown.append('admin_id',1)
      breakdown.append('brand_id',data?.brand)
       await axios.post(`admin/breakdown/update/${ID}`,breakdown)
      
      .then((response)=>{ 
        if(response?.data?.status === true){
        reset();
        dispatch(getBreakdowns());
        enqueueSnackbar(response?.data?.message);
       setEditShow(false)
      }})
    } catch (error) {
      enqueueSnackbar(error?.message,{ 
        variant: 'error'
      });
      console.error(error);
    }
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };

  const handleClose1 = () => {
    setEditShow(false);
    reset();
  };

  const handleEdit=(row)=>{
    setValue("name",row.breakdown_name)
    setValue("brand",row.brand_id)
    setID(row.id)
    setEditShow(true);
  }

  return (
    <Page title="Breakdown">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Breakdown"
          links={[{ name: '', href: '' }]}
          action={
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleShow}>
              New Breakdown
            </Button>
          }
        />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '10px',
                justifyContent: 'flex-center',
              }}
            >
            <IconButton
                color="success"
                sx={{
                  border: '1px solid',
                  borderColor: 'success.main',
                }}
                onClick={()=>{
                  handleEdit(row.original)
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                sx={{
                  border: '1px solid',
                  borderColor: 'error.main',
                }}
                onClick={() => {
                  handleDelete(row.original.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
          positionActionsColumn="last"
        />
      </Container>

      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
        <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Breakdown Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Stack spacing={3}>
                <RHFTextField name="name" label="Breakdown Name" />
                <RHFSelect name="brand" label="brand">
                <option value="" />
                {brands?.map((option) => (
                  <option key={option?.id} value={option?.id}>
                    {option?.brand_name}
                  </option>
                ))}
                </RHFSelect>
                </Stack>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer >
            <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
              Create Breakdown
            </LoadingButton>
          </Modal.Footer>
        </FormProvider>
      </Modal>
      <Modal show={editShow} onHide={handleClose1} aria-labelledby="contained-modal-title-vcenter" centered >
        <FormProvider methods={methods} onSubmit={handleSubmit(OnUpdate)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Breakdown </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
              <Stack spacing={3}>
              <RHFTextField name="name" label="Breakdown Name" />
              <RHFSelect name="brand" label="brand">
              <option value="" />
              {brands?.map((option) => (
                <option key={option?.id} value={option?.id}>
                  {option?.brand_name}
                </option>
              ))}
              </RHFSelect>
              </Stack>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer >
            <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
              Update Breakdown
            </LoadingButton>
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </Page>
  );
}
