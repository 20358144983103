// @mui
import { Stack } from '@mui/material';
import { DocIllustration } from '../../../assets';

export default function NavbarDocs() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />
      
    </Stack>
  );
}
