
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// console.log(JSON.parse(user1))
const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};
const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/', icon: ICONS.dashboard },
      { title: "RV's", path: PATH_DASHBOARD.supervisor.supervisor, icon: <Icon icon="material-symbols:rv-hookup-outline-rounded" /> },
      { title: 'Category', path: PATH_DASHBOARD.category.category, icon: <Icon icon="carbon:category" />,},
      { title: 'Brand', path: PATH_DASHBOARD.brand.brand, icon: <Icon icon="tabler:brand-nexo" />,},
      { title: 'Break down', path: PATH_DASHBOARD.breakdown.breakdown, icon: <Icon icon="tabler:brand-nexo" />,},
      { title: 'Product', path: PATH_DASHBOARD.product.product, icon: <Icon icon="iconoir:cart" /> },
      // { title: 'Sub Category', path: PATH_DASHBOARD.category.category, icon: <Icon icon="carbon:category-new-each" />,},
      // { title: 'Officer', path: PATH_DASHBOARD.officer.officer,   },
      // { title: 'Gang', path: PATH_DASHBOARD.gang.gang, },
      // { title: 'Gang Member', path: PATH_DASHBOARD.gangmember.member },
      // { title: 'Position', path: PATH_DASHBOARD.position.position  },
      // { title: 'Department', path: PATH_DASHBOARD.department.department},
      // { title: 'Vehicle', path: PATH_DASHBOARD.vehicle.vehicle},
]}
];

export default navConfig;
