import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import productReducer from './slices/product';
import rvReducer from './slices/rv';
import magictypeReducer from './slices/magictype';
import departReducer from './slices/department';
import vehicleReducer from './slices/vehicle';
import gangReducer from './slices/gang';
import categoryReducer from './slices/category';
import brandReducer from './slices/brand';
import breakdownReducer from './slices/breakdown';
import gangmember from './slices/Member';
import positionReducer from './slices/position';


const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({

  rv: rvReducer,
  magictype: magictypeReducer,
  depart: departReducer,
  vehicle: vehicleReducer,
  product: productReducer,
  gang: gangReducer,
  position: positionReducer,
  category: categoryReducer,
  brand: brandReducer,
  breakdown: breakdownReducer,
  member: gangmember,

});

export { rootPersistConfig, rootReducer };
